<template>
  <div class="company">
    <!-- 搜索区域 -->
    <div class="header">
      <el-input class="input" v-model="input" placeholder="输入企业名称" />
      <el-button type="primary" @click="searchInp">查询</el-button>
    </div>
    <!-- 表格区域 -->
    <div class="wrapper">
      <el-table :data="tableData" v-loading="loading" style="width: 100%;">
        <el-table-column label="学校logo" width="140">
          <template slot-scope="imgs">
            <div class="table-image">
              <el-image
                :src="imgs.row.pic"
                :preview-src-list="[imgs.row.pic]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="chain_name" label="学校名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="validity" label="到期时间" show-overflow-tooltip></el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <MyPagination
      :total="total"
      :pageSize="query_info.limit"
      @changePage="changePage"
      :currentPage="query_info.page"
    />
  </div>
</template>

<script>
import { getCompanyList } from '@/request/api'
import MyPagination from '../../../../components/MyPagination.vue'

export default {
  components: {
    MyPagination
  },
  data() {
    return {
      input: '',
      tableData: [],
      query_info: {
        limit: 10,
        page: 1
      },
      total: 0,
      loading: false
    }
  },
  methods: {
    // 搜索
    searchInp() {
      this.query_info.page = 1
      this.tableData = []
      this.getChainList()
    },
    // 获取企业列表
    async getChainList() {
      if (this.loading) return

      this.loading = true
      try {
        const res = await getCompanyList(this.query_info.limit, this.query_info.page, this.input)
        this.loading = false
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }

        this.tableData = res.data.data.chainList
        this.total = res.data.data.totalNumber
      } catch(error) {
        this.$message.error('请求发生错误：' + error.message);
        this.loading = false
      }
    },
    // 取分页的页码
    changePage(page) {
      this.query_info.page = page
      this.tableData = []
      this.getChainList()
    }
  },
  mounted() {
    this.getChainList()
  }
}
</script>

<style lang="less" scoped>
.company {
  padding: 20px;
  .header {
    display: flex;
    margin-bottom: 20px;
    .input {
      width: 240px;
      margin-right: 10px;
    }
  }
  .wrapper {
    .table-image {
      width: 70px;
      height: 70px;
    }
  }
}
</style>